import React from 'react'
import Chart from "react-apexcharts";
import Loader from "../../common/Loader";
import allApi from "../../../../api/allApi";
import { useNavigate } from "react-router-dom";
import ApexCharts from "react-apexcharts";
import { useEffect, useState } from "react";

function ChannelUtilizationExpandation() {
  const [ChannelSeries, setChannelSeries] = useState([]);
  const [ChannelCategories, setChannelCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [channelUtilizationDuration, setChannelUtilizationDuration] =
    useState("24h");
  const [ClientSeries, setClientSeries] = useState([]);

  useEffect(() => {
    getChannelUtilizationData(channelUtilizationDuration);
  }, [channelUtilizationDuration]);

  const handleChannelUtilizationDurationChange = (newDuration) => {
    setChannelUtilizationDuration(newDuration);
  };

  const getChannelUtilizationData = async (newDuration) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/totalChannelUtilization?commandType=5&duration=${newDuration}&networkId=6001`
      );
  
      if (response.status === "OK" && response.data) {
        const data = response.data;
  
        const categories = new Set();
        const seriesData = [];
  
        data.forEach((device) => {
          const deviceSeries = {
            name: device.serialNo,
            data: []
          };
  
          // Only one data point per device in this case
          const timestamp = device.trafficData.createdAt * 1000;
          const totalChannelUtil = device.trafficData.totalChannelUtil;
  
          deviceSeries.data.push({
            x: timestamp,
            y: totalChannelUtil
          });
  
          categories.add(timestamp);
          seriesData.push(deviceSeries);
        });
  
        // Sort categories
        const sortedCategories = Array.from(categories).sort((a, b) => a - b);
  
        console.log("Final seriesData:", seriesData);
        console.log("Final categories:", sortedCategories);
  
        setChannelCategories(sortedCategories);
        setChannelSeries(seriesData);
        setLoading(false);
      } else {
        console.error("Failed to fetch data:", response.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching channel utilization data:", error);
      setLoading(false);
    }
  };
  
  





  const ChannelOptions = {
    chart: {
      id: "channel-utilization-chart",
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: "datetime",
      categories: ChannelCategories,
      labels: {
        format: 'hh:mm',
        style: {
          colors: '#9aa0ac',
          fontSize: '12px',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: function (val) {
          return val !== undefined ? Math.round(val) : "N/A";
        },
      },
      title: {
        text: "Channel Utilization (%)",
      },
    },
    stroke: {
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: ChannelSeries.map((_, index) => {
      // Assign different colors for each series
      const colorArray = ["#fc7594", "#00aaff", "#4caf50", "#ff9800"];
      return colorArray[index % colorArray.length];
    }),
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  };
  

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>
              Channel Utilization
            </h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    All <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="#">
                        2.4 HHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        6 GHz
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {channelUtilizationDuration === "24h"
                      ? "Last 24 Hrs"
                      : channelUtilizationDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleChannelUtilizationDurationChange("8h")
                        }
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleChannelUtilizationDurationChange("24h")
                        }
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleChannelUtilizationDurationChange("7d")
                        }
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleChannelUtilizationDurationChange("30d")
                        }
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <input className="list-search" type="text" />
              <button className="icon-btn" title="Refresh">
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </button>
              <button className="icon-btn" title="Filter">
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            <div className='row'>
              <div className='col-lg-8'>
                {loading ? (
                  <Loader />
                ) : (
                  <div>
                    {ChannelSeries.length === 0 ? (
                      <div className="no-data-box">
                        <div className="no-data-text">No Data Available</div>
                      </div>
                    ) : (
                      <Chart
                        options={ChannelOptions}
                        series={ChannelSeries}
                        type="line"
                        height={170}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className='col-lg-4'>
                <div className='round-border'>
                  <div className="inc-card-table">
                    <table className="m-head">
                      <thead>
                        <tr>
                          <th>Devices</th>
                          <th>Channel Utilization</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ChannelSeries && ChannelSeries.length > 0 &&
                          ChannelSeries.map((seriesItem, seriesIndex) => (
                            seriesItem.data.map((dataItem, dataIndex) => (
                              <tr key={`${seriesIndex}-${dataIndex}`}>
                                <td>{seriesItem.name}</td>
                                <td>{dataItem.y}</td>
                              </tr>
                            ))
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChannelUtilizationExpandation;
