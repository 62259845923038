import React, { useState } from "react";
import deviceone from "../../../assets/images/r1.png";
import devicetwo from "../../../assets/images/r2.png";
import devicethree from "../../../assets/images/r3.png";
import devicefour from "../../../assets/images/r4.png";

function HTTPS() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleAddModelClick = () => {
    setIsModalOpen(true);
  };
  const handleSave = () => {
    // Add your save logic here
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>HTTPS Management</h3>
            <div className="inc-card-button">
              <button
                className="text-btn primary-btn"
                onClick={handleAddModelClick}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
                Add
              </button>

              <button class="icon-btn" title="Refresh">
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
              <button class="icon-btn" title="Delete">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            <div className="inc-card-table">
              <table className="full-table m-head device-pic">
                <thead>
                  <tr>
                    <th>
                      <label className="checkbox">
                        <input type="checkbox" />
                        <span className="indicator"></span>
                      </label>
                    </th>
                    <th colSpan={2}>Model Name</th>
                    <th>Device Type</th>
                    <th>Device communication protocol</th>
                    <th>Device monitoring interval</th>
                    <th>Device health interval</th>
                    <th>Current FW</th>
                    <th>Capabilities</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <label className="checkbox">
                        <input type="checkbox" />
                        <span className="indicator"></span>
                      </label>
                    </td>
                    <td>
                      <img src={deviceone} alt="Device" />
                    </td>
                    <td>Cisco ISR 4000</td>
                    <td>Routers</td>
                    <td>HTTP</td>
                    <td>1 Minute</td>
                    <td>30 Second</td>
                    <td>1.0.0.0</td>
                    <td>Radio enabled</td>
                    <td>
                      <div className="button-group">
                        <button title="Edit">
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="checkbox">
                        <input type="checkbox" />
                        <span className="indicator"></span>
                      </label>
                    </td>
                    <td>
                      <img src={devicetwo} alt="Device" />
                    </td>
                    <td>Nighthawk AX12</td>
                    <td>Routers</td>
                    <td>HTTP</td>
                    <td>30 Sec</td>
                    <td>1 Minute</td>
                    <td>1.0.2.0</td>
                    <td>Radio enabled</td>
                    <td>
                      <div className="button-group">
                        <button title="Edit">
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="checkbox">
                        <input type="checkbox" />
                        <span className="indicator"></span>
                      </label>
                    </td>
                    <td>
                      <img src={deviceone} alt="Device" />
                    </td>
                    <td>Archer AX6000</td>
                    <td>Routers</td>
                    <td>SNMP</td>
                    <td>2 Minute</td>
                    <td>5 Minute</td>
                    <td>2.0.0.0</td>
                    <td>Radio enabled</td>
                    <td>
                      <div className="button-group">
                        <button title="Edit">
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="checkbox">
                        <input type="checkbox" />
                        <span className="indicator"></span>
                      </label>
                    </td>
                    <td>
                      <img src={devicefour} alt="Device" />
                    </td>
                    <td>Catalyst 9000 Series</td>
                    <td>Switches</td>
                    <td>VLAN</td>
                    <td>50 sec</td>
                    <td>1 Minute</td>
                    <td>1.4.0.0</td>
                    <td>Radio enabled</td>
                    <td>
                      <div className="button-group">
                        <button title="Edit">
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="checkbox">
                        <input type="checkbox" />
                        <span className="indicator"></span>
                      </label>
                    </td>
                    <td>
                      <img src={deviceone} alt="Device" />
                    </td>
                    <td>Aruba 2930F Series</td>
                    <td>Switches</td>
                    <td>VLAN</td>
                    <td>5 Minute</td>
                    <td>2 Minute</td>
                    <td>5.1.0.1</td>
                    <td>Radio enabled</td>
                    <td>
                      <div className="button-group">
                        <button title="Edit">
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="checkbox">
                        <input type="checkbox" />
                        <span className="indicator"></span>
                      </label>
                    </td>
                    <td>
                      <img src={deviceone} alt="Device" />
                    </td>
                    <td>Firepower 2100 Series</td>
                    <td>Firewalls</td>
                    <td>HTTP</td>
                    <td>5 Minute</td>
                    <td>5 Minute</td>
                    <td>1.3.4.0</td>
                    <td>Radio enabled</td>
                    <td>
                      <div className="button-group">
                        <button title="Edit">
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="checkbox">
                        <input type="checkbox" />
                        <span className="indicator"></span>
                      </label>
                    </td>
                    <td>
                      <img src={devicetwo} alt="Device" />
                    </td>
                    <td>FortiGate 60E</td>
                    <td>Firewalls</td>
                    <td>HTTP</td>
                    <td>1 Minute</td>
                    <td>2 Minute</td>
                    <td>3.5.1.0</td>
                    <td>Radio enabled</td>
                    <td>
                      <div className="button-group">
                        <button title="Edit">
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="checkbox">
                        <input type="checkbox" />
                        <span className="indicator"></span>
                      </label>
                    </td>
                    <td>
                      <img src={devicefour} alt="Device" />
                    </td>
                    <td>Synology DiskStation DS220+</td>
                    <td>Network Attached Storage</td>
                    <td>HTTP</td>
                    <td>2 Minute</td>
                    <td>30 Second</td>
                    <td>1.7.0.7</td>
                    <td>Radio enabled</td>
                    <td>
                      <div className="button-group">
                        <button title="Edit">
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {isModalOpen && (
            <div className="customModal">
              <div className="c-modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Add Model</h5>
                    <button type="button" className="btn-close" onClick={handleCancel}></button>
                  </div>
                  <div className="modal-body">
                    <div className="add-ssid-body">
                      <form>
                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label
                              htmlFor="ModelName"
                              className="col-form-label"
                            >
                              Model Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="ModelName"
                              placeholder="Model Name"
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="DeviceType"
                              className="col-form-label"
                            >
                              Device Type
                            </label>
                            <select
                              className="form-select"
                              name="DeviceType"
                              placeholder="Device Type"
                            >
                              <option>Router</option>
                              <option>Switches</option>
                              <option>Access Points</option>
                              <option>Firewalls</option>
                            </select>
                          </div>
                        </div>

                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label
                              htmlFor="Protocol"
                              className="col-form-label"
                            >
                              Device Communication Protocol
                            </label>
                            <select
                              className="form-select"
                              name="Protocol"
                              placeholder="Protocol"
                            >
                              <option>Router</option>
                              <option>Switches</option>
                              <option>Access Points</option>
                              <option>Firewalls</option>
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="DeviceImage"
                              className="col-form-label"
                            >
                              Device Image
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              name="DeviceImage"
                              placeholder="DeviceImage"
                            />
                          </div>
                        </div>

                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label
                              htmlFor="MonitoringInterval"
                              className="col-form-label"
                            >
                              Device Monitoring Interval
                            </label>
                            <select
                              className="form-select"
                              name="MonitoringInterval"
                              placeholder="MonitoringInterval"
                            >
                              <option>1 Min</option>
                              <option>5 Min</option>
                              <option>10 Min</option>
                              <option>15 Min</option>
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="HealthInterval"
                              className="col-form-label"
                            >
                              Device Health Interval
                            </label>
                            <select
                              className="form-select"
                              name="HealthInterval"
                              placeholder="HealthInterval"
                            >
                              <option>1 Min</option>
                              <option>5 Min</option>
                              <option>10 Min</option>
                              <option>15 Min</option>
                            </select>
                          </div>
                        </div>

                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label
                              htmlFor="CurrentFW"
                              className="col-form-label"
                            >
                              Current FW
                            </label>
                            <select
                              className="form-select"
                              name="CurrentFW"
                              placeholder="CurrentFW"
                            >
                              <option>1.0.0.0</option>
                              <option>2.0.0.0</option>
                              <option>1.4.0.0</option>
                              <option>5.1.0.1</option>
                            </select>
                          </div>
                        </div>

                        <div className="border-line"></div>

                        <h4 className="mt-2">Capabilities</h4>
                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label htmlFor="port" className="col-form-label">
                              Suppoerted Port
                            </label>
                            <select
                              className="form-select"
                              name="port"
                              placeholder="port"
                            >
                              <option>8</option>
                              <option>12</option>
                              <option>16</option>
                              <option>32</option>
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="ClientSupport"
                              className="col-form-label"
                            >
                              MAX Client Support
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="ClientSupport"
                              placeholder="ClientSupport"
                            />
                          </div>
                          <div className="col-lg-6">
                            <label>&nbsp;</label>
                            <div className="switch-box">
                              <strong>Radio Enabled</strong>
                              <label className="ice-switch">
                                <input type="checkbox" name="Radio" />
                                <span className="slider"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HTTPS;
