import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import allApi from "../../../api/allApi";
import lanPort from "../../../assets/images/lan-port.png";
import { useNavigate } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Loader from "../common/Loader";

function HTTPSDevice() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [parameters, setParameters] = useState([]);
  const location = useLocation();
  const initialDeviceData = location.state?.deviceInfo || {};
  const initialGatewayId = location.state?.gateway_id || null;
  const [alarms, setAlarms] = useState([]);
  const [counts, setCounts] = useState({});
  const [gatewayDetails, setGatewayDetails] = useState(null);
  const gatewayId = location?.state?.gateway_id;
  const [deviceData, setDeviceData] = useState(initialDeviceData);
  const [deviceId, setDeviceId] = useState(initialDeviceData.deviceId || null);
  const [protocolType, setProtocolType] = useState(initialDeviceData.protocolType || null);
  const [deviceType, setDeviceType] = useState();
  const [managedByGateway, setManagedByGateway] = useState(
    location.state?.managed_by_gateway || null
  );
  const [connectedClients, setConnectedClients] = useState([]);
  const [dataUsage, setDataUsage] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState("1 Week");
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.protocolType) {
      handleDeviceInfo();
    }
  }, [location?.state?.protocolType]);

  useEffect(() => {
    if (deviceId && protocolType) {
      fetchAlarms(protocolType); 
    }
  }, [deviceId, protocolType]);

  useEffect(() => {
    if (deviceId) {
      handleDataUsage(deviceId);
    }
  }, [deviceId, selectedInterval]);

  const handleDeviceInfo = async () => {
    try {
      const api = new allApi();
      const payload = {
        protocolType: location.state.protocolType,
        deviceName: location.state.deviceName,
      };
      const response = await api.getRequest(`api/device`, payload);
      if (response) {
        setDeviceData(response);
        setDeviceId(response.deviceId);
        setProtocolType(response.protocolType); 
        if (response.deviceType) {
          setDeviceType(response.deviceType);
        }
      } else {
        console.error("Error fetching device data.");
      }
    } catch (error) {
      console.error("Error handling device:", error);
    }
  };

  const fetchAlarms = async (protocolType) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(`api/alarms/device`, { deviceId, protocolType });
      if (response) {
        setAlarms(response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching alarms data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const counts = {
      Critical: alarms.filter((alarm) => alarm.severityLevel === "CRITICAL")
        .length,
      Major: alarms.filter((alarm) => alarm.severityLevel === "MAJOR").length,
      Minor: alarms.filter((alarm) => alarm.severityLevel === "MINOR").length,
      Info: alarms.filter((alarm) => alarm.severityLevel === "INFO").length,
    };
    setCounts(counts);
  }, [alarms]);

  const fetchAlarmsbySeverity = async (severityLevelText) => {
    try {
      setLoading(true);
      const severityMapping = {
        Critical: 1,
        Major: 2,
        Minor: 3,
        Info: 4,
      };

      const formattedSeverityLevelText = severityLevelText.charAt(0).toUpperCase() + severityLevelText.slice(1).toLowerCase();

      const severityLevel = severityMapping[formattedSeverityLevelText];
      const api = new allApi();
      const response = await api.getRequest(
        `api/alarms?deviceId=${deviceId}&protocolType=${deviceData.deviceType}&severity_level=${severityLevel}`,
      );

      if (response) {
        const severityAlerts = response;
        navigate("/alarms", { state: { severityAlerts } });
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching alarms data:", error);
      setLoading(false);
    }
  };

  const handleViewAll = async (severityLevel) => {
    await fetchAlarmsbySeverity(severityLevel);
  }

  useEffect(() => {
    if (location.state && location.state.protocolType) {
      handleDeviceInfo();
    }
  }, []);

  useEffect(() => {
    clientListData();
  }, []);

  useEffect(() => {
    if (location.state && location.state.managed_by_gateway !== null) {
      setManagedByGateway(location.state.managed_by_gateway);
    }
  }, [location?.state?.managed_by_gateway]);

  const troubleshootDevice = async () => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(`api/http/device/${deviceId}`);
      if (response) {
        const deviceTroubleshoot = response;

        navigate(`/troubleshoot`, { state: { deviceTroubleshoot } });
      }
      setLoading(false);
    } catch (error) {
      console.error("error fetching device troubleshoot: ", error);
      setLoading(false);
    }
  };

  const handleDataUsage = async (deviceId) => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/channelUtilOfDevice?deviceId=${deviceId}&duration=24h`
      );

      if (response && response.data) {
        const formattedData = response.data.map((entry) => ({
          wlan0: entry.trafficData.wlan0,
          wlan1: entry.trafficData.wlan1,
          wlan2: entry.trafficData.wlan2,
          wlan2_6: entry.trafficData.wlan2_6,
          totalChannelUtil: entry.trafficData.totalChannelUtil,
          createdAt: new Date(entry.trafficData.createdAt * 1000).toLocaleTimeString(),
        }));

        setDataUsage(formattedData);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data usage:", error);
      setLoading(false);
    }
  };

  const clientListData = async () => {
    try {
      setLoading(true);
      const api = new allApi();
      const connectedPromise = api.getRequest(
        `api/client/connected/${deviceId}`
      );
      const [connectedResponse] = await Promise.all([connectedPromise]);

      if (Array.isArray(connectedResponse)) {
        setConnectedClients(connectedResponse);
      } else {
        console.error("Expected an array but got:", connectedResponse);
        setConnectedClients([]);
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
      setConnectedClients([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("Gateway Details:", gatewayDetails);
  }, [gatewayDetails]);

  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval);
  };

  function formatUpTime(deviceUptime) {
    if (!deviceUptime) return "N/A";
    const now = Date.now() / 1000; 
    const diffInSeconds = Math.floor(now - deviceUptime); 
    const days = Math.floor(diffInSeconds / (24 * 3600));
    const hours = Math.floor((diffInSeconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    return `${days} days ${hours} hours ${minutes} minutes`;
  }

  function formatLastSeen(lastSeenTimestamp) {
    if (!lastSeenTimestamp) return "N/A";
    const date = new Date(lastSeenTimestamp * 1000); 
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

  return (
    <div>
      <div className="device-info-wrap mb-4">
        <div className="inc-card info-card-4">
          <div className="inc-card-title">
            <h3>{deviceData?.serialNumber}</h3>
            <div className="inc-card-button">
              <button
                className="icon-btn"
                title="Troubleshoot"
                onClick={() => {
                  troubleshootDevice(deviceId);
                }}
              >
                <i className="troubleshoot icon" aria-hidden="true"></i>
              </button>
              <button
                className="icon-btn"
                title="Topology"
                onClick={() => {
                  navigate("/topology");
                }}
              >
                <i className="domain icon" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            <div className="device-info">
              <div>
                <strong>Device Name</strong>
                <span>{deviceData?.deviceName}</span>
              </div>
              <div>
                <strong>Device Type</strong>
                <span>{deviceData?.deviceType || "N/A"}</span>
              </div>
              <div>
                <strong>Device Model</strong>
                <span>{deviceData?.model || "N/A"}</span>
              </div>
              <div>
                <strong>Firmware Version</strong>
                <span>{deviceData?.firmwareVersion || "N/A"}</span>
              </div>
              <div>
                <strong>IP Address</strong>
                <span>{deviceData?.ipAddress || "N/A"}</span>
              </div>
              <div>
                <strong>MAC Address</strong>
                <span>{deviceData?.macAddress || "N/A"}</span>
              </div>
              <div>
                <strong>UP Time</strong>
                <span>{deviceData?.deviceUptime || "N/A"}</span>
              </div>
              <div>
                <strong>Health</strong>
                <span
                  style={{
                    color: deviceData?.health === "Online" ? "green" : "red",
                  }}
                >
                  {deviceData?.health === "Online" ? "Online" : "Offline"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="inc-card info-card-8">
          <div className="inc-card-title">
            <h3>Channel Utilisation</h3>
            <span
              className="dropdown"
              id="trendDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {selectedInterval}{" "}
              <i className="fa fa-angle-down" aria-hidden="true"></i>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="trendDropdown"
              >
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => handleIntervalChange("8 Hours")}
                  >
                    8 Hours
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => handleIntervalChange("24 Hours")}
                  >
                    24 Hours
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => handleIntervalChange("7 days")}
                  >
                    7 Days
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => handleIntervalChange("30 Days")}
                  >
                    30 Days
                  </a>
                </li>
              </ul>
            </span>
          </div>
          <div className="inc-card-body">
            {loading ? (
              <Loader />
            ) : dataUsage.length === 0 ? (
              <div className="no-data-box">
                <div className="no-data-text">No Data to display</div>
              </div>
            ) : (
              <ResponsiveContainer width="100%" height={300}>
                <LineChart
                  data={dataUsage}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="createdAt" />
                  <YAxis tickFormatter={(value) => `${value} MB`} />
                  <Tooltip formatter={(value, name) => [`${value} MB`, name]} />

                  <Line
                    type="monotone"
                    dataKey="wlan0"
                    stroke="#FF9050"
                    name="WLAN 0"
                  />

                  <Line
                    type="monotone"
                    dataKey="wlan1"
                    stroke="#4791FF"
                    name="WLAN 1"
                  />

                  <Line
                    type="monotone"
                    dataKey="wlan2"
                    stroke="#50FF79"
                    name="WLAN 2"
                  />

                  <Line
                    type="monotone"
                    dataKey="wlan2_6"
                    stroke="#FF5050"
                    name="WLAN 2.6"
                  />
                </LineChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-12">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Clients</h3>
              <div className="inc-card-button">
                <button
                  className="icon-btn"
                  title="Clients Details"
                  onClick={() => {
                    navigate("/client");
                  }}
                >
                  <i className="fa fa-external-link" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div className="inc-card-body">
              {managedByGateway !== null ? (
                <div className="inc-card-table">
                  <table className="m-head snmp-table">
                    <thead>
                      <tr>
                        <th>Clients</th>
                        <th>Associated Device</th>
                        <th>Device Model</th>
                        <th>SSID</th>
                        <th>Port</th>
                        <th>OS</th>
                        <th>MAC Address</th>
                        <th>IP Address</th>
                        <th>RSSI</th>
                      </tr>
                    </thead>
                    <tbody>
                      {connectedClients.map((client, index) => (
                        <tr key={index}>
                          <td>{client.deviceName || "N/A"}</td>
                          <td>{client.connDeviceName || "N/A"}</td>
                          <td>{client.connDeviceModel || "N/A"}</td>
                          <td>{client.ssid || "N/A"}</td>
                          {/* <td>{client.port || "N/A"}</td> */}
                          <td>{client.platform || "N/A"}</td>
                          <td>{client.macAddress || "N/A"}</td>
                          <td>{client.ipAddress || "N/A"}</td>
                          <td>{client.rssi || "N/A"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>No Client Found</div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-3">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="alarm-box">
                <span className="task-icon pie">
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                </span>
                <div>
                  <p className="task-type">Critical</p>
                  <div className="task-count">
                    <h2>{counts?.Critical}</h2>
                    <Link
                      onClick={() => handleViewAll("CRITICAL")}
                    >
                      View All{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
                <i className="fa fa-line-chart" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="alarm-box">
                <span className="task-icon fire">
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                </span>
                <div>
                  <p className="task-type">Major</p>
                  <div className="task-count">
                    <h2>{counts?.Major}</h2>
                    <Link
                      onClick={() => handleViewAll("MAJOR")}
                    >
                      View All{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
                <i className="fa fa-line-chart" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="alarm-box">
                <span className="task-icon flag">
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                </span>
                <div>
                  <p className="task-type">Minor</p>
                  <div className="task-count">
                    <h2>{counts?.Minor}</h2>
                    <Link
                      onClick={() => handleViewAll("MINOR")}
                    >
                      View All{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
                <i className="fa fa-line-chart" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="alarm-box">
                <span className="task-icon line">
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                </span>
                <div>
                  <p className="task-type">Info</p>
                  <div className="task-count">
                    <h2>{counts?.Info}</h2>
                    <Link
                      onClick={() => handleViewAll("INFO")}
                    >
                      View All{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
                <i className="fa fa-line-chart" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>All Parameters</h3>
              <div className="inc-card-button">
                <button className="icon-btn">
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div className="inc-card-body">
              <div className="dashboard-device-info">
                <div className="dashboard-device-detail">
                  <div className="detail-list">
                    <div>
                      <strong>{deviceData.temperature || "N/A"}</strong>
                      <span>Temperature</span>
                    </div>
                    <div>
                      <strong>{deviceData.txPower || "N/A"}</strong>
                      <span>TX Bytes</span>
                    </div>
                    <div>
                      <strong>{deviceData.txPower || "N/A"}</strong>
                      <span>RX Bytes</span>
                    </div>
                    <div>
                      <strong>{deviceData.createdAt || "N/A"}</strong>
                      <span>Device Added</span>
                    </div>
                    <div>
                      <strong>{formatUpTime(deviceData.deviceUptime)}</strong>
                      <span>Up Time</span>
                    </div>
                    <div>
                      <strong>{formatLastSeen(deviceData.lastSeen)}</strong>
                      <span>Last Seen</span>
                    </div>
                    <div>
                      <strong>{deviceData.assTimestamp || "N/A"}</strong>
                      <span>Associated Timestamp</span>
                    </div>
                    <div>
                      <strong>{deviceData.assTimestamp || "N/A"}</strong>
                      <span>Security</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HTTPSDevice;
