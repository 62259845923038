import React, { useEffect, useState } from "react";
import allApi from "../../../api/allApi";
import closeIcon from "../../../assets/images/close.svg";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import AllApi from "../../../api/allApi";
import Loader from "../common/Loader";
import DiscoverPopUp from "../common/DiscoverPopUp";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// import SockJS from "sockjs-client";
// import Stomp from "stompjs";
import errorIcon from "../../../assets/images/error-2.png";

function App() {
  const navigate = useNavigate();
  const [deviceListings, setDeviceListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddDevice, setShowAddDevice] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [devicesPerPage] = useState(10);
  const [totalDevices, setTotalDevices] = useState(0);
  const [isSearchOpen, setIsSearchOpen] = useState({
    serialNumber: false,
    deviceName: false,
    protocolType: false,
    swVersion: false,
    ipAddress: false,
    macAddress: false,
    lastInform: false,
    health: false,
  });
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [individualCheckboxChecked, setIndividualCheckboxChecked] = useState(
    {}
  );
  const [newDeviceData, setNewDeviceData] = useState({
    deviceIP: "",
    deviceName: "",
    deviceMac: "",
    protocolType: "",
    deviceModel: "",
    isManaged: false,
    snmpID: "",
    snmpVer: "v2c",
    workingMode: "",
    isManageable: "2",
  });
  const [showPopUp, setShowPopUp] = useState(false);
  const [modalClosed, setModalClosed] = useState(false);
  const [showDiscoverPopUp, setShowDiscoverPopUp] = useState(false);
  const [discoveredDevice, setDiscoveredDevice] = useState(null);
  const [showProgress, setShowProgress] = useState(false);
  const [webSocket, setWebSocket] = useState(null);
  const [socketStatus, setSocketStatus] = useState("Connecting...");
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const location = useLocation();
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [deviceToDelete, setDeviceToDelete] = useState(null);
  const [devicesResponse, setDevicesResponse] = useState([]);
  const [gatewayId, setGatewayId] = useState();

  // const [stompClient, setStompClient] = useState(null);

  // useEffect(() => {
  //   const socket = new SockJS('http://34.100.203.216:3000//websocket-endpoint');
  //   const stomp = Stomp.over(socket);
  //   stomp.connect({}, (frame) => {
  //     console.log('Connected to WebSocket');
  //     setStompClient(stomp);
  //     stomp.subscribe('/topic/request', (message) => {
  //       console.log('Received message:', message.body);
  //     });
  //   });

  //   return () => {
  //     socket.close();
  //   };
  // }, []);

  // const sendMessage = () => {
  //   if (stompClient) {
  //     const message = "Hello from client!";
  //     stompClient.send("/app/send", {}, message);
  //   } else {
  //     console.error("STOMP client is not connected.");
  //   }
  // };

  useEffect(() => {
    if(location.state && location.state.deviceStatus){
      const deviceStatus = location.state.deviceStatus;
      setDevicesResponse(deviceStatus);
        setDeviceListings(deviceStatus);
        setTotalDevices(deviceStatus.length);
        setLoading(false);
    }else{
    getDeviceDetails();
    }
  }, [location.state]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const health = params.get("health");
    if (health) {
      const filteredDevices = deviceListings.filter(
        (device) => device.health.toLowerCase() === health.toLowerCase()
      );
      setFilteredDevices(filteredDevices);
    } else {
      setFilteredDevices(deviceListings);
    }
  }, [deviceListings, location.search]);

  async function getDeviceDetails() {
    try {
      setLoading(true);
      const api = new allApi();
      const devicesResponse = await api.getRequest("api/devices");
      if (devicesResponse) {
        setDevicesResponse(devicesResponse);
        setDeviceListings(devicesResponse);
        setTotalDevices(devicesResponse.length);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching device data:", error);
      setLoading(false);
    }
  }

  const handlePerticularGateway = async (managedByGateway) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/gateway/all`);
      if (response) {
        const gatewayDetails = response.find(
          (managedBy) => managedBy.gateway_name === managedByGateway
        );
        return gatewayDetails;
      }
    } catch (error) {
      console.error("Error fetching gateway data:", error);
    }
    return null;
  };

  const handlePerticularDevice = async (protocolType, deviceName, response) => {
    console.log("Response Object:", response);
    console.log("Protocol Type:", protocolType);

    try {
      if (protocolType === "TR-069") {
        navigate(`/device-detail`, { state: { deviceInfo: response } });
      } else if (protocolType === "SNMP") {
        const managedByGateway = response?.managed_by_gateway || null;

        if (!managedByGateway) {
          console.error("Managed By Gateway is null or not defined.");
          navigate(`/device-snmp`, {
            state: {
              protocolType: protocolType,
              deviceName: deviceName,
              managed_by_gateway: "No Gateway",
              gateway_id: null,
            },
          });
          return;
        }

        const api = new allApi();
        const gatewayResponse = await api.getRequest(`api/gateway/all`);
        console.log("Gateway Response:", gatewayResponse);

        const gatewayDetails = gatewayResponse.find(
          (gateway) => gateway.gateway_name === managedByGateway
        );

        if (gatewayDetails) {
          navigate(`/device-snmp`, {
            state: {
              protocolType: protocolType,
              deviceName: deviceName,
              managed_by_gateway: managedByGateway,
              gateway_id: gatewayDetails.request_id,
            },
          });
        } else {
          console.error("Gateway details not found.");
        }
      } else if (protocolType === "http" || "ap") {
        const api = new allApi();
        const response = await api.getRequest(`api/device?protocolType=http&deviceName=${deviceName}`);
        if (response) {
          navigate(`/https-info`, { state: { deviceInfo: response } });
        } else {
          console.error("Error fetching HTTPS device data.");
        }
      }
    } catch (error) {
      console.error("Error in handlePerticularDevice:", error);
    }
  };

  const handleDeleteDevice = async (devicesToDelete) => {
    try {
      const api = new AllApi();
      const payload = devicesToDelete.map((device) => ({
        protocolType: device.protocolType,
        deviceName: device.deviceName,
      }));

      const response = await api.deleteRequest("api/delete", payload);

      if (response) {
        setDeviceListings((prevListings) =>
          prevListings.filter(
            (device) =>
              !devicesToDelete.some(
                (toDelete) => toDelete.macAddress === device.macAddress
              )
          )
        );
        console.log("Devices deleted successfully");
      } else {
        console.error("Failed to delete devices");
      }
    } catch (error) {
      console.error("Error deleting devices:", error);
    }
  };
  const handleDeleteButtonClick = () => {
    const devicesToDelete = deviceListings.filter(
      (device) => individualCheckboxChecked[device.macAddress]
    );
    handleDeleteDevice(devicesToDelete);
  };

  const handleReboot = async () => {
    try {
      const api = new AllApi();
      const devicesToReboot = deviceListings.filter(
        (device) => individualCheckboxChecked[device.macAddress]
      );
      const payload = devicesToReboot.map((device) => ({
        device_id: device.deviceId,
        action: "reboot",
      }));

      const response = await api.postRequest("api/snmp/reboot", "", payload);

      if (response) {
        console.log("Devices rebooted successfully");
      } else {
        console.error("Failed to reboot devices");
      }
    } catch (error) {
      console.error("Error rebooting devices:", error);
    }
  };

  const handleReset = async () => {
    try {
      const api = new AllApi();
      const devicesToReset = deviceListings.filter(
        (device) => individualCheckboxChecked[device.macAddress]
      );
      const payload = devicesToReset.map((device) => ({
        device_id: device.deviceId,
        action: "reset",
      }));

      const response = await api.postRequest("api/snmp/reset", "", payload);

      if (response) {
        console.log("Devices reset successfully");
      } else {
        console.error("Failed to reset devices");
      }
    } catch (error) {
      console.error("Error resetting devices:", error);
    }
  };

  const addDeviceToListing = async (device) => {
    try {
      setShowProgress(true);
      setDeviceListings((prevDeviceListings) => [
        ...prevDeviceListings,
        device,
      ]);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setShowProgress(false);
    } catch (error) {
      console.error("Error adding new device:", error);
    }
  };

  const handleAddDeviceToListing = async () => { };

  const handleSaveDiscover = async () => {
    try {
      setNewDeviceData(discoveredDevice);
      handleCloseAddDeviceSlider();
      setShowPopUp(true);
    } catch (error) {
      console.error("Error adding discovered device:", error);
    }
  };

  const handleCloseAddDeviceSlider = () => {
    setShowAddDevice(false);
  };

  const handleClose = () => {
    setShowPopUp(false);
    setModalClosed(true);
  };

  const handleAddButtonClick = () => {
    setShowDiscoverPopUp(true);
  };
  const handleDiscoverClose = () => {
    setShowDiscoverPopUp(false);
  };

  const formatTime = (lastInform) => {
    const formattedDate = moment(lastInform, "DD-MM-YYYY HH:mm:ss").format(
      "MM/DD/YYYY, HH:mm:ss"
    );
    const now = moment();
    const informTime = moment(formattedDate);
    const duration = moment.duration(now.diff(informTime));

    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${formattedDate} (${minutes} minutes ${seconds} seconds ago)`;
  };

  const indexOfLastDevice = currentPage * devicesPerPage;
  const indexOfFirstDevice = indexOfLastDevice - devicesPerPage;
  const totalFilteredDevices = filteredDevices.length;

  const currentDevices = filteredDevices.slice(
    indexOfFirstDevice,
    indexOfLastDevice
  );

  const handleSelectAllCheckbox = () => {
    setSelectAllChecked((prev) => !prev);
    const newCheckboxState = {};
    deviceListings.forEach((device) => {
      newCheckboxState[device.macAddress] = !selectAllChecked;
    });
    setIndividualCheckboxChecked(newCheckboxState);
  };

  const handleIndividualCheckboxChange = (macAddress) => {
    setIndividualCheckboxChecked((prev) => ({
      ...prev,
      [macAddress]: !prev[macAddress],
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDeviceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;
    if (value.trim() === "") {
      setFilteredDevices(deviceListings);
    } else {
      const filtered = deviceListings.filter((device) =>
        device[name]?.toString().toLowerCase().includes(value.toLowerCase())
      );
      setFilteredDevices(filtered);
    }
  };

  const handleSearchBox = (header) => {
    Object.keys(isSearchOpen).find((key) => {
      if (key == header) {
        isSearchOpen[key] = !isSearchOpen[key];
      } else {
        isSearchOpen[key] = false;
      }
    });
    setIsSearchOpen({ ...isSearchOpen });
  };

  const handleDashboardButtonClick = (deviceId) => {
    navigate(`/device-dashboard`, { state: { deviceId } });
  };

  const handleFilter = (health) => {
    if (health === "All") {
      const allDevices = deviceListings.filter(
        (device) => device.health.toLowerCase() === "All"
      );
      setFilteredDevices(allDevices);
    } else if (health === "Online") {
      const onlineDevices = deviceListings.filter(
        (device) => device.health.toLowerCase() === "Online"
      );
      setFilteredDevices(onlineDevices);
    } else if (health === "Offline") {
      const offlineDevices = deviceListings.filter(
        (device) => device.health.toLowerCase() === "Offline"
      );
      setFilteredDevices(offlineDevices);
    } else {
      setFilteredDevices(deviceListings);
    }
  };
  const totalPages = Math.ceil(totalFilteredDevices / devicesPerPage);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const successhandleClose = () => setShow(false);
  const successhandleShow = () => setShow(true);
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>Devices</h3>
            <div className="inc-card-button">
              <button
                className="text-btn primary-btn"
                onClick={() => {
                  handleAddButtonClick();
                  navigate("/device-discovery");
                }}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
                Add
              </button>
              <button
                className="text-btn"
                title={
                  Object.values(individualCheckboxChecked).some(
                    (isChecked) => isChecked
                  )
                    ? ""
                    : "Please select 1 device to reboot"
                }
                data-bs-toggle="modal"
                data-bs-target="#noDeviceReboot"
                disabled={Object.values(individualCheckboxChecked).every(
                  (isChecked) => !isChecked
                )}
              >
                Reboot
              </button>
              <button
                className="text-btn"
                title={
                  Object.values(individualCheckboxChecked).some(
                    (isChecked) => isChecked
                  )
                    ? ""
                    : "Please select 1 device to reset"
                }
                data-bs-toggle="modal"
                data-bs-target="#noDeviceReset"
                disabled={Object.values(individualCheckboxChecked).every(
                  (isChecked) => !isChecked
                )}
              >
                Reset
              </button>
              <button
                className="icon-btn"
                title="Refresh"
                onClick={() => getDeviceDetails()}
              >
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </button>
              {/* <button className="icon-btn" title="Push">
                <i className="fa fa-upload" aria-hidden="true"></i>
              </button> */}
              <button
                className="icon-btn"
                title={
                  Object.values(individualCheckboxChecked).some(
                    (isChecked) => isChecked
                  )
                    ? ""
                    : "Please select 1 device to delete"
                }
                data-bs-toggle="modal"
                data-bs-target="#noDeviceSelectedPopup"
                disabled={Object.values(individualCheckboxChecked).every(
                  (isChecked) => !isChecked
                )}
              //onClick={handleDeleteButtonClick}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>

              {/* <button className="icon-btn" title="Download">
                <i className="fa fa-download" aria-hidden="true"></i>
              </button> */}
              <button className="icon-btn" title="Tag">
                <i className="fa fa-tags" aria-hidden="true"></i>
              </button>
              {/* <span
                className="icon-btn dropdown"
                title="Filter"
                id="deviceFilter"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa fa-filter" aria-hidden="true"></i>
                <div
                  className="dropdown-menu dropdown-menu-end device-filter"
                  aria-labelledby="deviceFilter"
                >
                  <div className="filter-title">
                    <span>Filter</span>
                    <a href="#">Clear</a>
                  </div>
                  <div className="filter-body">
                    <input type="text" className="cal-input" />
                    <h4>States</h4>
                    <ul>
                      <li>
                        <input type="radio" name="radio" /> All
                      </li>
                      <li>
                        <input type="radio" name="radio" /> Online
                      </li>
                      <li>
                        <input type="radio" name="radio" /> Offline
                      </li>
                    </ul>
                  </div>
                  <div className="filter-button">
                    <button className="lineBtn">Cancel</button>
                    <button className="fillBtn">Apply</button>
                  </div>
                </div>
              </span> */}
            </div>
          </div>
          <div className="inc-card-body">
            {loading ? (
              <Loader />
            ) : (
              <div>
                <div className="inc-card-table">
                  <table className="full-table">
                    <thead>
                      <tr>
                        <th>
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              checked={selectAllChecked}
                              onChange={() => handleSelectAllCheckbox()}
                            />{" "}
                            <span className="indicator"></span>
                          </label>
                        </th>
                        <th>Serial Number</th>
                        <th>Device Name</th>
                        <th>Device Type</th>
                        <th>SW Version</th>
                        <th>IP Address</th>
                        <th>MAC Address</th>
                        <th>Edge Server</th>
                        <th>Last Inform</th>
                        <th>Health</th>
                        <th>Action</th>
                      </tr>
                      <tr className="search-border">
                        <td></td>
                        <td>
                          <input
                            className="searchBox smallWidth"
                            type="text"
                            name="serialNumber"
                            onKeyUp={handleSearch}
                          />
                          <i
                            className="fa fa-sort-amount-desc"
                            onClick={() => handleSearchBox("serialNumber")}
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td>
                          <input
                            className="searchBox smallWidth"
                            type="text"
                            name="deviceName"
                            onKeyUp={(e) => handleSearch(e)}
                          />{" "}
                          <i
                            className="fa fa-sort-amount-desc"
                            onClick={() => handleSearchBox("deviceName")}
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td>
                          <input
                            className="searchBox smallWidth"
                            type="text"
                            name="protocolType"
                            onKeyUp={(e) => handleSearch(e)}
                          />{" "}
                          <i
                            className="fa fa-sort-amount-desc"
                            onClick={() => handleSearchBox("protocolType")}
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td>
                          <input
                            className="searchBox smallWidth"
                            type="text"
                            name="softwareVersion"
                            onKeyUp={(e) => handleSearch(e)}
                          />
                          <i
                            className="fa fa-sort-amount-desc"
                            onClick={() => handleSearchBox("swVersion")}
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td>
                          <input
                            className="searchBox smallWidth"
                            type="text"
                            name="ip"
                            onKeyUp={(e) => handleSearch(e)}
                          />
                          <i
                            className="fa fa-sort-amount-desc"
                            onClick={() => handleSearchBox("ipAddress")}
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td>
                          <input
                            className="searchBox smallWidth"
                            type="text"
                            name="macAddress"
                            onKeyUp={(e) => handleSearch(e)}
                          />
                          <i
                            className="fa fa-sort-amount-desc"
                            onClick={() => handleSearchBox("macAddress")}
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td>
                          <input
                            className="searchBox smallWidth"
                            type="text"
                            name="managed_by_gateway"
                            onKeyUp={handleSearch}
                          />
                          <i
                            className="fa fa-sort-amount-desc"
                            onClick={() =>
                              handleSearchBox("managed_by_gateway")
                            }
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td>
                          <input
                            className="searchBox smallWidth"
                            type="text"
                            name="lastInform"
                            onKeyUp={(e) => handleSearch(e)}
                          />
                          <i
                            className="fa fa-sort-amount-desc"
                            onClick={() => handleSearchBox("lastInform")}
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td>
                          <input
                            className="searchBox smallWidth"
                            type="text"
                            name="health"
                            onKeyUp={(e) => handleSearch(e)}
                          />
                          <i
                            className="fa fa-sort-amount-desc"
                            onClick={handleFilter}
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {currentDevices.map((device, index) => (
                        <tr key={index}>
                          <td>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                checked={
                                  individualCheckboxChecked[
                                  device.macAddress
                                  ] || false
                                }
                                onChange={() =>
                                  handleIndividualCheckboxChange(
                                    device.macAddress
                                  )
                                }
                              />
                              <span className="indicator"></span>
                            </label>
                          </td>
                          <td>
                            <span
                              className="device-info0 underline"
                              onClick={() =>
                                handlePerticularDevice(
                                  device.protocolType,
                                  device.deviceName,
                                  device
                                )
                              }
                            >
                              {device.serialNumber || "N/A"}
                            </span>
                          </td>
                          <td>{device.deviceName || "N/A"}</td>
                          <td>{device.deviceType|| "N/A"}</td>
                          <td>{device.softwareVersion || "N/A"}</td>
                          <td>{device.ip || "N/A"}</td>
                          <td>{device.macAddress || "N/A"}</td>
                          <td>{device.managed_by_gateway || "N/A"}</td>
                          <td>{device.lastInform || "N/A"}</td>
                          <td>
                            <span
                              className={
                                "d-status " +
                                (device?.health?.toLowerCase() === "offline"
                                  ? "offline"
                                  : device?.health?.toLowerCase() === "online"
                                    ? "online"
                                    : "awaitingConnection")
                              }
                            >

                              {device.health}
                            </span>{" "}
                          </td>
                          <td>
                            <div className="button-group">
                              <button
                                title="Dashboard"
                                onClick={() =>
                                  handleDashboardButtonClick(
                                    device.deviceId,
                                    device.serialNumber
                                  )
                                }
                              >
                                <i
                                  className="fa fa-th-large"
                                  aria-hidden="true"
                                ></i>
                              </button>

                              <button
                                title="Settings"
                                onClick={(e) => {
                                  navigate("/device-settings");
                                }}
                              >
                                <i className="fa fa-cog" aria-hidden="true"></i>
                              </button>
                              <button
                                title="Delete"
                                data-bs-toggle="modal"
                                data-bs-target="#noDeviceSelectedPopup"
                                onClick={() =>
                                  handleDeleteDevice({
                                    protocolType: device.protocolType,
                                    deviceName: device.deviceName,
                                  })
                                }
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
          <div className="pagination-container">
            <div className="pagination-info">
              <p>
                Showing{" "}
                {Math.min(currentPage * devicesPerPage, totalFilteredDevices)}{" "}
                of {totalFilteredDevices} records
              </p>
            </div>
            <div className="pagination">
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                Previous
              </button>
              {[...Array(Math.min(totalPages, 10)).keys()].map((page) => (
                <button
                  key={page}
                  onClick={() => handlePageClick(page + 1)}
                  className={currentPage === page + 1 ? "active" : ""}
                >
                  {page + 1}
                </button>
              ))}
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
              <button
                onClick={handleLastPage}
                disabled={currentPage === totalPages}
              >
                Last Page
              </button>
            </div>
          </div>
        </div>
      </div>
      <DiscoverPopUp
        showPopUp={showDiscoverPopUp}
        handleClose={handleDiscoverClose}
        headerMessage1="Onboard Device"
        handleSaveDiscover={handleSaveDiscover}
        addDeviceToListing={addDeviceToListing}
        newDeviceData={newDeviceData}
        handleInputChange={handleInputChange}
        closeIcon={closeIcon}
        getDeviceDetails={getDeviceDetails}
        setDiscoveredDevice={setDiscoveredDevice}
      />


      <div
        className={`modal fade`}
        id="noDeviceSelectedPopup"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="customModal confirm-modal">
          <div className="modal-dialog modal-dialog-centered small">
            <div className="modal-content inc-modal">
              <div className="modal-body error-msg">
                <img src={errorIcon} alt="error" />
                <p >All information related to this device will be permanently removed.</p>
                <h3>Are you sure want to delete?</h3>
                <div className="modal-footer">
                  <button
                    className="text-btn secondary-btn"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                  <button className="text-btn primary-btn"
                    data-bs-dismiss="modal"
                    onClick={() => handleDeleteButtonClick()}>Yes</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade`}
        id="noDeviceReboot"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content inc-modal">
            <div className="modal-body success-msg">
              <img src={errorIcon} alt="error" />
              <p>Are you sure want to Reboot?</p>
              <button
                className="text-btn primary-btn"
                data-bs-dismiss="modal"
                onClick={() => handleReboot()}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade`}
        id="noDeviceReset"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content inc-modal">
            <div className="modal-body success-msg">
              <img src={errorIcon} alt="error" />
              <p>Are you sure want to Reset?</p>
              <button
                className="text-btn primary-btn"
                data-bs-dismiss="modal"
                onClick={() => handleReset()}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
